define("discourse/plugins/discourse-category-flair-mapping/discourse/connectors/category-custom-settings/category-flair-mapping-settings", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "select-kit/components/group-chooser", "discourse-common/helpers/i18n", "@ember/render-modifiers/modifiers/did-update", "@ember/render-modifiers/modifiers/did-insert", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _groupChooser, _i18n, _didUpdate, _didInsert, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryFlairMappingSettings extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "selectedGroups", [_tracking.tracked], function () {
      return null;
    }))();
    #selectedGroups = (() => (dt7948.i(this, "selectedGroups"), void 0))();
    constructor() {
      super(...arguments);
      this.updateSelectedGroups(); // Run initially
    }
    updateSelectedGroups() {
      if (!this.args.outletArgs.category) {
        this.selectedGroups = [];
        return;
      }
      let groupIds1 = (this.args.outletArgs.category.custom_fields.avatar_flair_groups || "").split(",").filter(Boolean).map(id1 => parseInt(id1, 10));
      this.selectedGroups = this.site.groups.filter(group1 => groupIds1.includes(group1.id)).map(group1 => group1.name);
    }
    refreshOnCategoryChange() {
      this.updateSelectedGroups();
    }
    static #_4 = (() => dt7948.n(this.prototype, "refreshOnCategoryChange", [_object.action]))();
    get availableGroups() {
      return (this.site.groups || []).map(g1 => {
        return g1.id === 0 ? null : g1.name;
      }).filter(Boolean);
    }
    static #_5 = (() => dt7948.n(this.prototype, "availableGroups", [(0, _object.computed)("site.groups.[]")]))();
    onChangeGroups(groupNames1) {
      this.selectedGroups = groupNames1;
      let groupIds1 = this.site.groups.filter(group1 => groupNames1.includes(group1.name)).map(group1 => group1.id);
      this.args.outletArgs.category.custom_fields.avatar_flair_groups = groupIds1.join(",");
    }
    static #_6 = (() => dt7948.n(this.prototype, "onChangeGroups", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          {{didInsert this.refreshOnCategoryChange}}
          {{didUpdate this.refreshOnCategoryChange this.args.outletArgs.category}}>
          <section>
            <h3>{{i18n "category.category_flair_mapping.title"}}</h3>
          </section>
          <section class="field">
            <label>
              {{i18n "category.category_flair_mapping.avatar_flair_groups_description"}}
            </label>
            <div class="value">
              <GroupChooser
                @content={{this.availableGroups}}
                @valueProperty={{null}}
                @nameProperty={{null}}
                @value={{this.selectedGroups}}
                @onChange={{this.onChangeGroups}}
              />
            </div>
          </section>
        </div>
      
    */
    {
      "id": "JvWJTV5d",
      "block": "[[[1,\"\\n    \"],[11,0],[4,[32,0],[[30,0,[\"refreshOnCategoryChange\"]]],null],[4,[32,1],[[30,0,[\"refreshOnCategoryChange\"]],[30,0,[\"args\",\"outletArgs\",\"category\"]]],null],[12],[1,\"\\n      \"],[10,\"section\"],[12],[1,\"\\n        \"],[10,\"h3\"],[12],[1,[28,[32,2],[\"category.category_flair_mapping.title\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[\"category.category_flair_mapping.avatar_flair_groups_description\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n          \"],[8,[32,3],null,[[\"@content\",\"@valueProperty\",\"@nameProperty\",\"@value\",\"@onChange\"],[[30,0,[\"availableGroups\"]],null,null,[30,0,[\"selectedGroups\"]],[30,0,[\"onChangeGroups\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-category-flair-mapping/discourse/connectors/category-custom-settings/category-flair-mapping-settings.js",
      "scope": () => [_didInsert.default, _didUpdate.default, _i18n.default, _groupChooser.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryFlairMappingSettings;
  ;
});